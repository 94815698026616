import React, { useContext, useEffect } from 'react'

// Components
import Footer from '../../organisms/Footer'
import Header from '../../organisms/Header'

// Constants
import { LOCALIZATION } from '../../../config/constants/localStorage'

// GrpahQL
import { getGraphLang } from '../../../graphQL'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Styles
import { mainContainer } from './styles.module.scss'

// Types
import { ValidLanguage } from '../../../types'
import AdBanner from '../../organisms/AdBanner'

interface Props {
  children: React.ReactNode
}

const PageTemplate: React.FC<Props> = ({ children, ...attr }) => {
  const activeLanguages = getGraphLang()

  const { language, updateLanguage } = useContext(LocalizationContext)

  useEffect(() => {
    const lang = localStorage.getItem(LOCALIZATION)
    /* Case LOCALIZTION doesn't exist */
    if (typeof lang === 'undefined' || lang === null) {
      localStorage.setItem(LOCALIZATION, 'ENG')
    } else {
      /* Case LOCALIZATION is not valid */
      const activeLang = activeLanguages?.filter(
        (language) => language.code === lang
      )
      if (activeLang?.length === 0) {
        localStorage.setItem(LOCALIZATION, 'ENG')
      } else if (language !== lang) {
        updateLanguage(lang as ValidLanguage)
      }
    }
  }, [activeLanguages])

  return (
    <div className={mainContainer}>
      <Header />
      <AdBanner/>
      {children}
      <Footer />
    </div>
  )
}

export default PageTemplate
