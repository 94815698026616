import React, { useEffect } from 'react'

// Styles
import { adWrapper, adContainer } from './styles.module.scss'

const AdBanner: React.FC = ({ ...attr }) => {
  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [])

  return (
    <div className={`${adWrapper} block-wrapper`}>
      <div className={`${adContainer} container`}>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3473260525556751"
          crossOrigin="anonymous"
        ></script>
        <ins
          className="adsbygoogle"
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
          data-ad-client="ca-pub-3473260525556751"
          data-ad-slot="6744697784"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </div>
  )
}

declare global {
  interface Window {
    adsbygoogle: {[key: string]: unknown}[];
  }
}

export default AdBanner
